import { Body18, Title20 } from 'components/Typography'
import { GatsbyImage } from 'gatsby-plugin-image'
import { rem, transitions } from 'polished'
import { isMobile } from 'react-device-detect'
import styled, { keyframes, css } from 'styled-components'
import theme from 'styles/theme'

const fadeInUp = keyframes`
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
`

const fadeOutUp = keyframes`
  100% {
    opacity: 0.08;
    transform: translateY(-40px);
  }
    80% {
    opacity: 0.30;
    transform: translateY(-20px);
  }
  0% {
    opacity: 1;
    transform: translateY(0);
  }
`

// Animated styles with dynamic delay
const animatedStyles = css`
  animation: ${fadeInUp} 0.4s ease-in forwards;
`

export const NewOfferCardContainer = styled.article`
  ${transitions('margin-top 0.2s, transform 1s')}
  background-color: #f0f0f0;
  height: 250px;
  border: 0px solid transparent;
  flex: 1 0 100%;
  position: relative;
  text-align: center;

  width: 100%;
  ${(props) =>
    props.animateOut &&
    css`
      opacity: 1;
      animation: ${fadeOutUp} ${isMobile ? 1 : 0.3}s ease-in forwards;
      animation-delay: ${props.delay + (isMobile ? 0.8 : 0.3)}s;
    `}
  ${(props) =>
    props.animate &&
    css`
      opacity: 0;
      ${animatedStyles};
      animation-delay: ${props.delay}s;
    `}
  ${theme.mediaQueries.mobile} {
    &:nth-child(odd) {
      margin-top: ${({ isCardSelected }) =>
        isCardSelected ? '-0px;' : '-61px;'};
    }

    .card-wrap {
      grid-column: 2;
    }
  }
  ${theme.mediaQueries.small} {
    height: 371px;
    width: 100%;
    &:nth-child(odd) {
      margin-top: -80px;
    }
    &:nth-child(1),
    &:nth-child(3) {
      margin-top: -80px;
    }
  }
`

export const NewOfferCardImg = styled(GatsbyImage)`
  display: block;
  height: 100%;
  width: 100%;

  ::before {
    ${transitions('height 0.4s')}
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${({ selected }) => (selected ? '100%' : '60%')};
    min-height: 10vh;
    z-index: 1;
    background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111 100%);
    opacity: ${({ selected }) => (selected ? '0.5' : '1')};
    background: ${({ selected, cardColor }) =>
      selected
        ? `linear-gradient(0deg,  #${cardColor} 0%, #${cardColor} 100%) lightgray 50% / cover no-repeat;`
        : 'linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111 100%);'};
  }
`

export const NewOfferCardBody = styled.div`
  color: ${theme.colors.white};
  position: absolute;
  width: 100%;
  bottom: 0%;
  padding: ${rem(8)};
  left: 0;
  text-align: left;
  z-index: 2;

  ${theme.mediaQueries.medium} {
    padding: ${rem(14.3)};
  }
`

export const NewOfferCardLink = styled.a`
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
`

export const NewOfferCardWrapper = styled.div`
  height: 100%;
  width: 100%;
`

export const NewOfferCardTitle = styled(Body18)`
  font-size: ${rem(19.4)};
  color: ${theme.colors.sagefeather};
  line-height: initial;
  font-family: BrownStd-Regular;
  font-weight: 400;

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(14)};
  }
`

export const NewOfferCardSubtitle = styled(Title20)`
  display: block;
  font-family: 'BrownStd-Light';
  font-weight: 300;
  font-size: ${rem(19.4)};
  line-height: initial;

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(16)};
  }
  p {
    ${theme.textStyles.title16}
  }
`

export const NewOfferCardBrand = styled(Title20)`
  color: ${theme.colors.sagefeather};
  padding-top: ${rem(8)};

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(16)};
  }

  ${theme.mediaQueries.medium} {
    padding-top: ${rem(16)};
  }
`
