import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { rem } from 'polished'
import useScrollListener, { SCROLL_DIRECTIONS } from 'hooks/useScrollListener'
import { OffersContextDefinition, alphabetFilter } from 'contexts/offers'
import FilterIcon from 'components/Icons/FilterIcon'
import CloseIcon from 'components/Icons/CloseIcon'
import FloatingIsland from 'components/FloatingIsland/FloatingIsland'
import Toggle from 'components/Toggle'
import GA4 from 'utils/GA4'
import {
  Button,
  Container,
  IconButton,
  LetterFilterButton,
  PhatonButton,
  IslandRow,
  IslandRowGroup,
} from 'components/FloatingIsland/FloatingIsland.style'
import { ActiveFiltersWrapper } from './OffersSection.style'
import OffersCategoriesFilter from './OffersCategoriesFilter'

const delayAmount = { scroll: 250, iddle: 250 }

const OffersFloatingIsland = ({ applyTopGutter, targetElement }) => {
  const {
    rawOffers,
    groupedOffers,
    selectedCategories,
    displayCategories,
    displayList,
    displayOnlyTopOffers,
    isMobile,
    floatingIslandEnabled,
    hideTopOffers,
    selectCategory,
    selectedLetter,
    selectLetter,
    village,
    toggleDisplayCategories,
    toggleOnlyTopOffers,
    toggleDisplayAsList,
    clearAll,
    entitySettings: {
      floatIslandBG,
      floatIslandOnListViewBG,
      offerCardPrimaryBG,
      viewAllLabel,
      topOffersLabel,
    },
  } = useContext(OffersContextDefinition)

  const { isScrolling, lastScrollDirection } = useScrollListener({
    targetElement,
    delayAmount,
  })

  const handleToggleListGridView = () => {
    toggleDisplayAsList()
    GA4('toggle_selection', {
      component: 'offers_gallery',
      village_name: village.name,
      toggle_selected: !displayList ? 'list_view' : 'gallery_view',
    })
  }

  const selectLetterClick = (letter) => {
    selectLetter(letter)
    GA4('az_directory_click', {
      component: 'offers_gallery',
      village_name: village.name,
      toggle_state: displayList ? 'list_view' : 'gallery_view',
      alphabet: letter,
    })
  }

  const toggleOnlyTopOffersClick = () => {
    toggleOnlyTopOffers()
    GA4('top_offers_click', {
      component: 'offers_gallery',
      village_name: village.name,
      toggle_state: displayList ? 'list_view' : 'gallery_view',
    })
  }

  const clearAllClick = () => {
    clearAll()
    GA4('view_all_click', {
      component: 'offers_gallery',
      village_name: village.name,
      toggle_state: displayList ? 'list_view' : 'gallery_view',
    })
  }

  const shouldHideIsland = !floatingIslandEnabled || (isMobile && isScrolling)

  if (rawOffers.length < 1) return null

  return (
    <FloatingIsland
      scrollingTop={
        applyTopGutter || lastScrollDirection === SCROLL_DIRECTIONS.up
      }
      hide={shouldHideIsland}>
      <Container
        hide={shouldHideIsland}
        isMobile={isMobile}
        bg={displayList ? `#${floatIslandOnListViewBG}` : floatIslandBG}>
        <IslandRow noWrap>
          <IslandRowGroup>
            <PhatonButton onClick={clearAllClick}>{viewAllLabel}</PhatonButton>
            {!hideTopOffers && (
              <Button
                on={displayOnlyTopOffers}
                level="secondary"
                onClick={toggleOnlyTopOffersClick}
                secondary={displayList}
                bg={displayList && `#${offerCardPrimaryBG}`}>
                {topOffersLabel}
              </Button>
            )}
            {selectedCategories.length > 0 && (
              <ActiveFiltersWrapper>
                {selectedCategories.map((category) => (
                  <PhatonButton
                    onClick={() => selectCategory(category)}
                    extraStyle="text-decoration: underline;">
                    {category.label}
                    <CloseIcon width="18px" />
                  </PhatonButton>
                ))}
              </ActiveFiltersWrapper>
            )}
          </IslandRowGroup>
          <IslandRowGroup>
            <IconButton
              on={displayCategories}
              onClick={toggleDisplayCategories}
              bg={displayList && `#${offerCardPrimaryBG}`}>
              <FilterIcon width="14px" />
            </IconButton>
            <Toggle on={displayList} onPress={handleToggleListGridView} />
          </IslandRowGroup>
        </IslandRow>
        <IslandRow hideOnMobile extraStyle={`padding: ${rem(12)};`}>
          {alphabetFilter.map((letter) => {
            const isDisabled = !Object.prototype.hasOwnProperty.call(
              groupedOffers,
              letter
            )

            return (
              <LetterFilterButton
                disabled={isDisabled}
                on={selectedLetter === letter}
                key={`letter-filter-button-${letter}`}
                onClick={() => selectLetterClick(letter)}>
                {letter}
              </LetterFilterButton>
            )
          })}
        </IslandRow>
      </Container>
      {displayCategories && !isMobile && <OffersCategoriesFilter />}
    </FloatingIsland>
  )
}

OffersFloatingIsland.propTypes = {
  applyTopGutter: PropTypes.bool,
  targetElement: PropTypes.oneOfType([PropTypes.object]),
}

OffersFloatingIsland.defaultProps = {
  applyTopGutter: false,
  targetElement: null,
}

export default OffersFloatingIsland
