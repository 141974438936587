import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import useScrollListener from 'hooks/useScrollListener'
import { ParallaxBox } from './Parallax.styles'

const Parallax = ({
  children,
  speed,
  elementSizeRelated,
  scrollThrottleAmount,
  extraStyle,
}) => {
  const elementRef = useRef(null)

  const onScroll = useCallback(() => {
    if ((window || window.document) && elementRef.current) {
      const distanceController = elementSizeRelated ? '%' : 'px'
      const displaceAmount = Math.round(
        elementSizeRelated
          ? (window.scrollY / speed / 100) * 2
          : window.scrollY * speed
      )
      elementRef.current.style.setProperty(
        'transform',
        `translateY(${displaceAmount}${distanceController})`
      )
    }
  }, [])

  const onScrollParallax = () => window.requestAnimationFrame(onScroll)

  useScrollListener({
    delayAmount: { scroll: scrollThrottleAmount },
    onScroll: onScrollParallax,
    noDelay: scrollThrottleAmount < 50,
  })

  return (
    <ParallaxBox ref={elementRef} extraStyle={extraStyle}>
      {children}
    </ParallaxBox>
  )
}

Parallax.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  speed: PropTypes.number,
  elementSizeRelated: PropTypes.bool,
  scrollThrottleAmount: PropTypes.number,
  extraStyle: PropTypes.string,
}

Parallax.defaultProps = {
  children: null,
  speed: 0.5,
  elementSizeRelated: false,
  scrollThrottleAmount: 100,
  extraStyle: '',
}

export default Parallax
