import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { OffersContextDefinition } from 'contexts/offers'
import { Spacing60, Spacing48 } from 'styles/sharedStyle'
import NewOfferCardDetail from 'components/NewOfferCardDetail/NewOfferCardDetail'
import GA4 from 'utils/GA4'
import {
  Wrapper,
  Container,
  ListSection,
  SectionHeader,
  Offer,
  OfferContent,
  OfferBrand,
} from './OffersList.style'

const getGroupToRender = (offers) => {
  if (offers.lengh < 2) return offers

  return Object.keys(offers)
    .map((letter) => [letter, offers[letter]])
    .sort((a, b) => {
      if (a[0] === b[0]) return 0

      if (a[0] === '#') return 1

      return a[0] > b[0] ? 1 : -1
    })
}

const OffersList = ({ detailsExtraProps }) => {
  const [selectedOffer, setSelectedOffer] = useState(null)
  const [displayDetails, setDisplayDetails] = useState(false)
  const {
    floatingIslandEnabled,
    displayOffers,
    isMobile,
    entitySettings: { offerGridListViewBG },
  } = useContext(OffersContextDefinition)

  const setDisplayDetailsClick = (offer) => {
    GA4('offer_card_click', {
      component: 'offers_gallery',
      brand_name: offer.brandLink.name,
      offer_title: offer.offerTitle,
      village_name: detailsExtraProps.village.name,
      toggle_state: 'list_view',
    })
    setDisplayDetails(true)
  }

  const groupToRender = getGroupToRender(displayOffers)

  return (
    <Wrapper>
      <Container
        floatingIslandVisible={floatingIslandEnabled}
        bg={offerGridListViewBG}
        isMobile={isMobile}>
        {groupToRender.map((group, index) => (
          <>
            {index > 0 && <Spacing60 />}
            <SectionHeader>{group[0]}</SectionHeader>
            <Spacing48 />
            <ListSection>
              {group[1].map((offer) => (
                <Offer
                  key={offer.id}
                  data-element-identifier="isOffer"
                  onClick={() => {
                    setSelectedOffer(() => offer)
                    setDisplayDetailsClick(offer)
                  }}>
                  <OfferBrand>{offer.brandLink.name}</OfferBrand>
                  <OfferContent underlineOnHover>
                    {offer.offerTitle}
                  </OfferContent>
                  <OfferContent>{offer.offerSubtitle}</OfferContent>
                </Offer>
              ))}
            </ListSection>
          </>
        ))}
      </Container>
      {displayDetails && (
        <NewOfferCardDetail
          isModalOpen={displayDetails && selectedOffer}
          close={() => {
            setSelectedOffer(() => null)
            setDisplayDetails(() => false)
          }}
          {...selectedOffer}
          {...detailsExtraProps}
          inverted
        />
      )}
    </Wrapper>
  )
}

OffersList.propTypes = {
  detailsExtraProps: PropTypes.oneOfType([PropTypes.object]),
}

OffersList.defaultProps = {
  detailsExtraProps: {},
}

export default OffersList
