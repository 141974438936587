import React, { useContext, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { OffersContextDefinition } from 'contexts/offers'
import MobileButton from 'components/MobileButton/MobileButton'
import ArrowSettingsIcon from 'components/Icons/ArrowSettingsIcon'
import FullscreenModal from 'components/FullscreenModal/FullscreenModal'
import OffersFloatingIsland from 'components/OffersSection/OffersFloatingIsland'
import { ScrollToTop } from './OffersList.style'

const MobileOfferList = ({ children }) => {
  const modalRef = useRef()
  const {
    mobileFrame,
    toggleMobileFrame,
    entitySettings: { offerGridListViewBG },
  } = useContext(OffersContextDefinition)

  const scrollTop = useCallback(() => {
    if (window && window?.document && modalRef.current) {
      modalRef.current.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [modalRef.current])

  return (
    <FullscreenModal ref={modalRef} background={`#${offerGridListViewBG}`}>
      <OffersFloatingIsland
        applyTopGutter
        targetElement={modalRef.current}
        showFloatingIsland
      />
      {children}
      <MobileButton
        onClick={toggleMobileFrame}
        closeButton={mobileFrame}
        targetElement={modalRef.current}
      />
      <ScrollToTop onClick={scrollTop}>
        <ArrowSettingsIcon width="23.83px" transform="scale(1.1)" />
      </ScrollToTop>
    </FullscreenModal>
  )
}

MobileOfferList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
}

export default MobileOfferList
