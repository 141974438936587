import React, { useEffect, useLayoutEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { normalizeInternalUrl } from 'utils/urlUtils'
import { OffersContextDefinition } from 'contexts/offers'
import { scrollIntoView } from 'hooks/useElementVisibility'
import NewOfferCard from 'components/NewOfferCard/NewOfferCard'
import VisitCard from 'components/VisitCard/VisitCard'
import { isMobile } from 'react-device-detect'
import NewOfferCardDetail from 'components/NewOfferCardDetail/NewOfferCardDetail'
import NewOfferCardDetailMobile from 'components/NewOfferCardDetail/mobile/NewOfferCardDetailMobile'
import useOfferCardDetail from './useOfferCardDetail'
import {
  GridContainer,
  GridFooterGradient,
  GridSection,
} from './NewOfferGrid.styles'
import GridPreview from '../GridPreview/GridPreview'

const NewOfferGrid = ({
  heroCTA1,
  heroCTA1Link,
  visitCardProps,
  settings,
  village,
  nodeLocale,
}) => {
  const animationThreshold = isMobile ? 4 : 8
  const {
    offers,
    offersPreviewMode,
    shouldAnimateGrid,
    setFloatingIslandEnabled,
    setOffersPreviewMode,
    setShouldAnimateGrid,
  } = useContext(OffersContextDefinition)

  const {
    isCardSelected,
    selectedOffer,
    showBigCard,
    gridRow,
    setIsCardSelected,
    setIndexSelected,
    close,
  } = useOfferCardDetail(offers, isMobile)

  const redirectToVisit = () => {
    navigate(
      `/${village.villageSlug}/${nodeLocale}${normalizeInternalUrl(
        settings[0]?.visitURL ?? '#'
      )}`
    )
  }

  const onDismissOffersPreview = () => {
    setOffersPreviewMode(false)
    setTimeout(() => {
      setFloatingIslandEnabled(true)
      setShouldAnimateGrid(false)
    }, 3000)
  }

  const onOfferPreviewClick = (index) => {
    setOffersPreviewMode(false)
    setFloatingIslandEnabled(false)
    setIsCardSelected(true)
    setIndexSelected(index)
  }

  const onIndexSelected = (index) => {
    setFloatingIslandEnabled(false)
    setIndexSelected(index)
  }

  const onCloseDetails = () => {
    close()
    setFloatingIslandEnabled(true)
  }

  useEffect(() => {
    if (!shouldAnimateGrid && !offersPreviewMode && !isCardSelected) {
      const timeoutRef = setTimeout(() => setFloatingIslandEnabled(true), 4000)
      return () => clearTimeout(timeoutRef)
    }

    return () => null
  }, [
    offers.length,
    animationThreshold,
    shouldAnimateGrid,
    offersPreviewMode,
    isCardSelected,
  ])

  useLayoutEffect(() => {
    scrollIntoView('offers-grid-section')
  }, [offers.length])

  return (
    <GridSection id="offers-grid-section" shouldAnimate={shouldAnimateGrid}>
      {offers.length > animationThreshold && shouldAnimateGrid && (
        <GridPreview
          isActive={offersPreviewMode}
          items={offers}
          onDismiss={onDismissOffersPreview}
          onOfferClick={onOfferPreviewClick}
        />
      )}
      <GridContainer
        shouldAnimate={shouldAnimateGrid}
        isCardSelected={isCardSelected}
        showFullGrid={!offersPreviewMode}
        id="grid-offers">
        {offers.map((offer, index) => {
          const columnIndex = index % (isMobile ? 2 : 4)
          const shouldShowVisitCard = index + (1 % 20) === 0 || index + 1 === 10
          return (
            <>
              <NewOfferCard
                village={village}
                showFullGrid={!offersPreviewMode}
                key={`${offer.id}-1`}
                {...offer}
                columnIndex={columnIndex}
                heroCTA1={heroCTA1}
                heroCTA1Link={heroCTA1Link}
                settings={settings}
                setIsCardSelected={setIsCardSelected}
                index={index}
                setIndexSelected={onIndexSelected}
                isModalOpen={selectedOffer && selectedOffer.id === offer.id}
                isCardSelected={isCardSelected}
              />
              {shouldShowVisitCard ? (
                <VisitCard
                  key={`visit-card-${offer.id}`}
                  {...visitCardProps}
                  onClick={() => redirectToVisit()}
                  isCardSelected={isCardSelected}
                />
              ) : null}
            </>
          )
        })}
        {showBigCard && (
          <NewOfferCardDetailMobile
            gridRow={gridRow}
            offer={selectedOffer}
            settings={settings}
            heroCTA1={heroCTA1}
            heroCTA1Link={heroCTA1Link}
            village={village}
            nodeLocale={nodeLocale}
            close={onCloseDetails}
          />
        )}

        {isCardSelected && !isMobile && (
          <NewOfferCardDetail
            isModalOpen={selectedOffer && isCardSelected}
            close={onCloseDetails}
            village={village}
            nodeLocale={nodeLocale}
            settings={settings}
            {...selectedOffer}
          />
        )}
      </GridContainer>
      <GridFooterGradient visible={!offersPreviewMode} />
    </GridSection>
  )
}

NewOfferGrid.propTypes = {
  islandMethods: PropTypes.shape({
    hideFloatingIsland: PropTypes.func,
    openFloatingIsland: PropTypes.func,
  }).isRequired,
  visitCardProps: PropTypes.shape({}),
  heroCTA1: PropTypes.string,
  heroCTA1Link: PropTypes.string,
  url: '',
  newOfferProps: PropTypes.shape({
    showArrow: PropTypes.bool,
    isTransitioning: PropTypes.bool,
    showFullGrid: PropTypes.bool,
    openAllOffersClick: PropTypes.func,
  }),
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      floatIslandBG: PropTypes.string,
      id: PropTypes.string,
      offerCardColour2: PropTypes.string,
      offerCardPrimaryBG: PropTypes.string,
      offerGridArrowColour: PropTypes.string,
      offerGridListViewBG: PropTypes.string,
      offerGridMasterHL: PropTypes.string,
      updatedAt: PropTypes.string,
      visitCTA: PropTypes.string,
      visitTitle: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string,
        }),
      }),
      visitURL: PropTypes.string,
    })
  ),
  village: PropTypes.shape({
    villageSlug: PropTypes.string,
  }),
  nodeLocale: PropTypes.string,
}

NewOfferGrid.defaultProps = {
  visitCardProps: {},
  heroCTA1: '',
  heroCTA1Link: '',
  url: '',
  newOfferProps: {
    showArrow: true,
    isTransitioning: false,
    showFullGrid: false,
    openAllOffersClick: () => {},
  },
  settings: [
    {
      floatIslandBG: '',
      id: '',
      offerCardColour2: '',
      offerCardPrimaryBG: '',
      offerGridArrowColour: '',
      offerGridListViewBG: '',
      offerGridMasterHL: '',
      updatedAt: '',
      visitCTA: '',
      visitTitle: { childMarkdownRemark: { html: '' } },
      visitURL: '',
    },
  ],
  village: {
    villageSlug: '',
  },
  nodeLocale: '',
}

export default NewOfferGrid
