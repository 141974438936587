import React from 'react'
import PropTypes from 'prop-types'
import { ArrowIconWrapper, ArrowPageWrapper, ArrowWrapper } from './Arrow.style'

const Arrow = ({ colour, marginTop, onClick, icon, animation }) => {
  return (
    <ArrowPageWrapper
      onClick={onClick}
      type="button"
      marginTop={marginTop}
      animation={animation}>
      <ArrowWrapper colour={colour}>
        <ArrowIconWrapper>{icon}</ArrowIconWrapper>
      </ArrowWrapper>
    </ArrowPageWrapper>
  )
}

Arrow.propTypes = {
  colour: PropTypes.string,
  marginTop: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.element,
  animation: PropTypes.bool,
}

Arrow.defaultProps = {
  colour: '',
  marginTop: '',
  onClick: () => {},
  icon: <></>,
  animation: false,
}

export default Arrow
