import theme from 'styles/theme'
import styled, { css, keyframes } from 'styled-components'
import { Container } from 'styles/sharedStyle'
import { rem } from 'polished'

const showGrid = keyframes`
  from {
    opacity: 0;
  }
  50% {
    opacity: 0;
    max-width: 90%;
  }
  to {
    opacity: 1;
    display: grid;
    max-width: 1200px;
  }
`

// eslint-disable-next-line import/prefer-default-export
export const GridContainer = styled(Container)`
  z-index: 1;
  padding-top: ${rem(55)};
  gap: 15px;
  display: none;
  grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
  position: relative;
  max-width: 1200px;
  opacity: 0;

  ${(props) =>
    props?.showFullGrid &&
    css`
      ${props?.shouldAnimate &&
        css`
          animation-fill-mode: forwards;
          animation: ${css`
              ${showGrid}`} 2s 1;
        `}
      display: grid;
      opacity: 1;
    `}

  ${theme.mediaQueries.mobile} {
    padding-top: ${rem(75)};
    padding-left: ${theme.space[4]};
    padding-right: ${theme.space[4]};
  }

  ${theme.mediaQueries.medium} {
    grid-template-columns: repeat(4, 1fr); /* 4 columns for web */
    gap: 29px;
    transition: opacity 0.5s ease, transform 1s ease, max-width 1s ease;
    padding-top: ${rem(96)};
  }
`

export const GridFooterGradient = styled(Container)`
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 100%
  );
  height: 308px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: sticky;
  position: -webkit-sticky;
  left: 0;
  margin-top: -308px;
  max-width: 100%;
  ${theme.mediaQueries.mobile} {
    height: 170.687px;
    margin-top: -170.687px;
  }
`

export const GridSection = styled.section`
  position: relative;
  width: 100%;
  margin-top: 0;

  ${(props) =>
    props?.shouldAnimate &&
    css`
      min-height: calc((295px * 2) + 32px);

      ${theme.mediaQueries.medium} {
        min-height: calc(((25vw + 7.5vw) * 2.2) + 58px);
      }
    `}
`
