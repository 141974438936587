import React from 'react'
import PropTypes from 'prop-types'
import useSmoothScroll from 'hooks/useSmoothScroll'

const SSContainer = ({ children }) => {
    useSmoothScroll()
    return <>{children}</>
}

SSContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.object, null])
}

SSContainer.defaultProps = {
    children: null
}

export default SSContainer