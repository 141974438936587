import styled, { css, keyframes } from 'styled-components'
import { rem } from 'polished'
import theme from 'styles/theme'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10%);
  }
`

const shrink = keyframes`
  from {
    opacity: 1;
    max-width: 100%;
  }
  to {
    opacity: 0;
    max-width: 90%;
    display: none;
  }
`

export const GridPreview = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;

  ${(props) =>
    props?.hide &&
    css`
      animation: ${css`
          ${shrink}`} 0.75s 1;
      animation-fill-mode: forwards;
    `}
`

export const PreviewTab = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${rem(16)};
  padding-left: ${theme.space[4]};
  padding-right: ${theme.space[4]};

  & > * {
    padding-top: 46px;
  }

  & > *:nth-child(even) {
    padding-top: 106px;
  }

  ${theme.mediaQueries.medium} {
    column-gap: ${rem(29)};
    grid-template-columns: repeat(4, 1fr);
    padding-left: initial;
    padding-right: initial;

    & > * {
      padding-top: initial;
    }

    & > *:nth-child(even) {
      padding-top: 76px;
    }
  }
`

export const PreviewColumn = styled.div`
  display: flex;
  flex-flow: column;
  row-gap: ${rem(16)};
  opacity: 0;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-duration: 0.42s;
  animation-delay: ${(props) => props.anmDelay ?? '0'}s;
  animation-name: none;

  ${(props) =>
    props.isActive &&
    css`
      animation-name: ${css`
        ${fadeIn}
      `};
    `}

  ${(props) =>
    props.wasActive &&
    css`
      opacity: 1;
      animation-name: ${css`
        ${fadeOut}
      `};
    `}

  & > article:nth-child(1), & > article:nth-child(odd) {
    margin-top: initial;
  }

  & article {
    transition: height 1s, min-height 1s, max-height 1s;
    height: 250px;
    max-height: 250px;
    margin-top: initial;
  }

  ${theme.mediaQueries.small} {
    & article {
      transition: height 1s, min-height 1s, max-height 1s;
      height: 60vw;
      max-height: 60vw;
      min-height: 60vw;
      margin-top: initial;
    }
  }

  ${theme.mediaQueries.medium} {
    row-gap: ${rem(29)};

    & article {
      transition: height 1s, min-height 1s, max-height 1s;
      height: calc(25vw + 7.5vw);
      max-height: calc(25vw + 7.5vw);
      min-height: calc(25vw + 7.5vw);
      margin-top: initial;
    }
  }
`
