import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import GA4 from 'utils/GA4'
import { alphabetFilter, OffersContextDefinition } from 'contexts/offers'
import {
  MobileCharacterBoxWrapper,
  MobileCharacterWrapper,
  MobileFrameKeyBoardWrapper,
  MobileFrameWrapper as StyledMobileFrameWrapper,
} from './MobileFrame.style'

const MobileFrame = ({ isVisible, bg, buttonBg }) => {
  const [animateIn, setAnimateIn] = useState(false)

  const {
    displayList,
    groupedOffers,
    selectedLetter,
    selectLetter,
    village,
  } = useContext(OffersContextDefinition)

  useEffect(() => {
    if (isVisible) {
      setAnimateIn(true)
    }
  }, [isVisible])

  const onAnimationEnd = () => {
    if (!isVisible) {
      setAnimateIn(false)
    }
  }

  const selectLetterClick = (letter) => {
    selectLetter(letter)
    GA4('az_directory_click', {
      component: 'offers_gallery',
      village_name: village.name,
      toggle_state: displayList ? 'list_view' : 'gallery_view',
      alphabet: letter,
    })
  }

  return (
    <StyledMobileFrameWrapper
      isVisible={isVisible}
      animateIn={animateIn}
      onAnimationEnd={onAnimationEnd}
      bg={bg}>
      <MobileFrameKeyBoardWrapper>
        {alphabetFilter.map((letter) => {
          const isDisabled = !Object.prototype.hasOwnProperty.call(
            groupedOffers,
            letter
          )
          const isActive = selectedLetter === letter

          return (
            <MobileCharacterBoxWrapper
              key={`mobileAlphabet-${letter}`}
              on={isActive}
              disabled={isDisabled}
              onClick={() => selectLetterClick(letter)}
              bg={buttonBg}>
              <MobileCharacterWrapper>{letter}</MobileCharacterWrapper>
            </MobileCharacterBoxWrapper>
          )
        })}
      </MobileFrameKeyBoardWrapper>
    </StyledMobileFrameWrapper>
  )
}

MobileFrame.propTypes = {
  isVisible: PropTypes.bool,
  bg: PropTypes.string,
  buttonBg: PropTypes.string,
}

MobileFrame.defaultProps = {
  isVisible: false,
  bg: '',
  buttonBg: '',
}

export default MobileFrame
