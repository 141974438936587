import React from 'react'
import PropTypes from 'prop-types'
import GA4 from 'utils/GA4'
import {
  NewOfferCardBody,
  NewOfferCardBrand,
  NewOfferCardTitle,
  NewOfferCardSubtitle,
  NewOfferCardContainer,
  NewOfferCardImg,
  NewOfferCardWrapper,
} from './NewOfferCard.styles'

const NewOfferCard = ({
  media,
  offerTitle,
  offerSubtitle,
  brandLink,
  settings,
  setIsCardSelected,
  setIndexSelected,
  isModalOpen,
  village,
  displayList,
  index,
  isCardSelected,
  columnIndex,
  showFullGrid,
}) => {
  const handleModalOpen = () => {
    setIsCardSelected(true)
    setIndexSelected(index)
    GA4('offer_card_click', {
      component: 'offers_gallery',
      brand_name: brandLink.name,
      offer_title: offerTitle,
      village_name: village.name,
      toggle_state: displayList ? 'list_view' : 'gallery_view',
    })
  }
  return (
    <NewOfferCardContainer
      showFullGrid={showFullGrid}
      columnIndex={columnIndex}
      id={`offer-${index}`}
      selected={isModalOpen}
      isCardSelected={isCardSelected}>
      <NewOfferCardWrapper
        data-element-identifier="isOffer"
        onClick={handleModalOpen}>
        <NewOfferCardImg
          selected={isModalOpen}
          cardColor={settings[0].offerCardPrimaryBG}
          image={media.portrait.gatsbyImageData}
          alt={media.altText ?? 'offer-image'}
        />
        <NewOfferCardBody>
          <NewOfferCardTitle color="white">{offerTitle}</NewOfferCardTitle>
          {offerSubtitle && (
            <NewOfferCardSubtitle>{offerSubtitle}</NewOfferCardSubtitle>
          )}
          <NewOfferCardBrand color="white">{brandLink.name}</NewOfferCardBrand>
        </NewOfferCardBody>
      </NewOfferCardWrapper>
    </NewOfferCardContainer>
  )
}

NewOfferCard.propTypes = {
  media: PropTypes.shape({
    altText: PropTypes.string,
    portrait: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    landscape: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
    __typename: PropTypes.string,
  }),
  offerTitle: PropTypes.string,
  offerSubtitle: PropTypes.string,
  village: PropTypes.string.isRequired,
  displayList: PropTypes.bool.isRequired,
  brandLink: PropTypes.shape({ name: PropTypes.string }),
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      floatIslandBG: PropTypes.string,
      id: PropTypes.string,
      offerCardColour2: PropTypes.string,
      offerCardPrimaryBG: PropTypes.string,
      offerGridArrowColour: PropTypes.string,
      offerGridListViewBG: PropTypes.string,
      offerGridMasterHL: PropTypes.string,
      updatedAt: PropTypes.string,
      visitCTA: PropTypes.string,
      visitTitle: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string,
        }),
      }),
      visitURL: PropTypes.string,
    })
  ),
  setIsCardSelected: PropTypes.func,
  setIndexSelected: PropTypes.func,
  isModalOpen: PropTypes.bool,
  index: PropTypes.number,
  isCardSelected: PropTypes.bool,
  columnIndex: PropTypes.number.isRequired,
  showFullGrid: PropTypes.bool,
}

NewOfferCard.defaultProps = {
  media: {
    altText: '',
    portrait: { gatsbyImageData: {} },
    landscape: { gatsbyImageData: {} },
    __typename: '',
  },
  offerTitle: '',
  offerSubtitle: '',
  brandLink: {
    name: '',
  },
  settings: [
    {
      floatIslandBG: '',
      id: '',
      offerCardColour2: '',
      offerCardPrimaryBG: '',
      offerGridArrowColour: '',
      offerGridListViewBG: '',
      offerGridMasterHL: '',
      updatedAt: '',
      visitCTA: '',
      visitTitle: { childMarkdownRemark: { html: '' } },
      visitURL: '',
    },
  ],
  setIsCardSelected: () => {},
  setIndexSelected: () => {},
  isModalOpen: false,
  index: 0,
  isCardSelected: false,
  showFullGrid: false,
}

export default NewOfferCard
