// import { rem } from 'polished'
import styled from 'styled-components'
// import theme from 'styles/theme'
import { fadeInAnimation } from 'styles/animation'

export const Section = styled.section`
  ${(props) => props?.background && ` background: ${props.background};`}
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  overflow: auto;
  animation: ${fadeInAnimation} 0.2s ease-in-out;
`

export default {
  Section,
}
