import { rem, transitions } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'
import { buttonShadow } from 'styles/sharedStyle'

const toggleMobileSize = rem(24)
const toggleDesktopSize = rem(30)

const borderRadius = `border-radius: ${rem(24)};`

export const Slider = styled.div`
  ${buttonShadow}
  ${borderRadius}
  ${transitions('background-color 0.4s')};
  padding: ${rem(2.6)};
  background-color: ${(props) =>
    props?.on ? theme.colors.teal : theme.colors.pastelTeal};
  min-width: calc(${toggleMobileSize} * 2);
  display: flex;
  flex-flow: row no-wrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  ${theme.mediaQueries.medium} {
    min-width: calc(${toggleDesktopSize} * 2);
    padding: ${rem(4.29)};
  }
`

export const Button = styled.button`
  ${borderRadius}
  ${transitions('margin-left 0.4s')};
  display: block;
  border: none;
  padding: ${rem(5)};
  margin-left: ${(props) =>
    props?.on ? `calc(100% - ${toggleMobileSize})` : '0'};
  width: ${toggleMobileSize};
  height: ${toggleMobileSize};

  ${theme.mediaQueries.medium} {
    margin-left: ${(props) =>
      props?.on ? `calc(100% - ${toggleDesktopSize})` : '0'};
    padding: ${rem(6)};
    width: ${toggleDesktopSize};
    height: calc(${toggleDesktopSize} - 2px);
  }
`

export const Icon = styled.div`
  display: flex;
  flex-flow: column no-wrap;
  justify-content: center;
  margin: 0;
  padding: 0;

  & > * {
    max-width: ${rem(14)};
    max-height: ${rem(14)};
  }

  ${theme.mediaQueries.medium} {
    & > * {
      max-width: ${rem(17)};
      max-height: ${rem(17)};
    }
  }
`

export default {
  Slider,
  Button,
  Icon,
}
