import theme from 'styles/theme'
import styled from 'styled-components'
import { rem, transitions } from 'polished'
import { Container, buttonShadow } from 'styles/sharedStyle'
import { PhatonButton } from 'components/FloatingIsland/FloatingIsland.style'

// eslint-disable-next-line import/prefer-default-export
export const Section = styled.section`
  position: relative;
  ${(props) => props?.mt && `margin-top: ${props.mt};`}
`

export const OffersCategoriesFilterWrapper = styled.div`
  * {
    color: ${theme.colors.white};
  }
  ${(props) =>
    props?.stickToBottom &&
    `
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 5;
  `}
`

export const OfferCategoryGrid = styled(Container)`
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(1, 1fr);
  max-height: 50vh;
  overflow: auto;
  place-items: center;

  ${theme.mediaQueries.mobile} {
    padding: 0 ${theme.space[4]};
  }

  ${theme.mediaQueries.small} {
    gap: 14px;
    grid-template-columns: repeat(3, calc(((100% / 3) - 14px) + (14px / 3)));
  }
`

export const SelectedFilterButton = styled.button`
  ${transitions('background-color 0.2s', 'border 0.2s')}

  border: none;
  background-color: transparent;
  text-decoration: underline;
  
  span {
    line-height: 0.5;
    vertical-align: middle;

    path {
      fill: ${theme.colors.white};
    }
  }
  
  &:hover, &:focus {
    ${buttonShadow}
    background-color: ${theme.colors.white};
    color: ${theme.colors.teal};
  
    span path {
      fill: ${theme.colors.teal};
    }
  }

  &:active {
    ${buttonShadow}
    background-color: ${theme.colors.pastelTeal};
    color: ${theme.colors.white};
  
    span path {
      fill: ${theme.colors.white};
    }
  }

`

export const CategoryFilterButton = styled.button`
  ${transitions('background-color 0.2s', 'border 0.2s')}
  ${buttonShadow}
  border: 1px solid ${theme.colors.white};
  color: ${theme.colors.white};
  background: ${theme.colors.pastelTeal};
  padding: ${rem(18)} ${rem(34)};
  text-align: left;

  display: flex;
  flex-flow: row no-wrap;
  justify-content: space-between;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;

  * {
    fill: ${theme.colors.white};
  }

  &:hover,
  &:focus {
    background: ${theme.colors.white};
    color: ${theme.colors.teal};

    * {
      fill: ${theme.colors.teal};
    }
  }

  &:active {
    background: ${theme.colors.teal};
    color: ${theme.colors.white};

    * {
      fill: ${theme.colors.white};
    }
  }

  ${(props) => props?.on && `background: ${theme.colors.teal};`}
`

export const CategoryInternalContainer = styled.div`
  padding: 0;

  ${theme.mediaQueries.medium} {
    padding: ${rem(10)} ${rem(90)} ${rem(35)} ${rem(90)};
  }
`

export const FilterCategoryName = styled.span`
  display: block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: inherit;
  line-height: normal;
  vertical-align: middle;
  font-family: BrownStd-Light, sans-serif;

  & + span {
    line-height: 0.5;
  }
`

export const CloseButton = styled(PhatonButton)`
  ${transitions('background-color 0.2s', 'border 0.2s')}
  * {
    fill: ${theme.colors.white};
  }
`

export const ActiveFiltersWrapper = styled.div`
  width: 100%;
  display: none;
  overflow-x: auto;
  flex-flow: row no-wrap;

  ${theme.mediaQueries.medium} {
    display: flex;
    flex-flow: row no-wrap;
  }
`
