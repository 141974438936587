import styled from 'styled-components'
import { rem, transitions } from 'polished'
import theme from 'styles/theme'

const boxShadow = 'box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);'
const padding = `padding: ${rem(7)} ${rem(13.8)} ${rem(8.6)} ${rem(13.8)};`

export const MobileButtonWrapper = styled.div`
  ${transitions('opacity 0.2s')}
  display: flex;
  width: ${rem(76)};
  padding: ${rem(17)};
  justify-content: center;
  align-items: center;
  gap: ${rem(29)};
  border-radius: ${rem(39)};
  background: ${(props) => (props?.bg ? props.bg : theme.colors.teal)};
  position: sticky;
  position: -webkit-sticky;
  margin: 0 auto;
  cursor: pointer;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  ${(props) =>
    props?.hide &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`

export const MobileCenterWrapper = styled.div`
  ${padding}
  ${boxShadow}
  display: flex;
  height: ${rem(34)};
  justify-content: center;
  align-items: center;
  border-radius: ${rem(39)};
  background: ${theme.colors.white};
`

export const MobileTextWrapper = styled.div`
  color: ${theme.colors.teal};
  font-family: BrownStd;
  font-size: ${rem(14)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  line-height: 1;
`
export const MobileButtonCloseWrapper = styled.div`
  ${padding}
  ${boxShadow}
  display: inline-flex;
  width: ${rem(49.5)};
  height: ${rem(34.7)};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: ${rem(25)};
  background: ${(props) => (props?.bg ? props.bg : theme.colors.teal)};
  cursor: pointer;
  bottom: ${rem(20)};
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  bottom: 43px;
  z-index: 10;
`
export const MobileCloseWrapper = styled.div`
  display: flex;
  width: ${rem(31.7)};
  height: ${rem(31.7)};
  padding: ${rem(5)};
  justify-content: center;
  align-items: center;
  gap: ${rem(2.5)};
  flex-shrink: 0;
  border-radius: ${rem(31.7)};
  color: ${theme.colors.white};
  span {
    width: 20px;
    height: 20px;
  }
`
