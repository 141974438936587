import React from 'react'
import { Spacing20 } from 'styles/sharedStyle'
import PropTypes from 'prop-types'

import {
  CampaignHeadline,
  CampaignHeadlineContainer,
  CampaignHeadlineIntroText,
  CampaignHeadlineOfferNumber,
  CampaignSubHeadline,
  ContainerTemplate,
  TermsAndConditions,
} from './CampaignOfferHeader.style'

const CampaignOfferHeader = ({
  introText,
  offerLength,
  headline,
  subheadline,
  termsAndConditions,
}) => {
  return (
    <ContainerTemplate>
      {introText && (
        <CampaignHeadlineIntroText
          id="campaign-text"
          html={introText.childMarkdownRemark.html}
        />
      )}
      {headline && (
        <CampaignHeadlineContainer>
          <CampaignHeadlineOfferNumber
            key={offerLength}
            id="campaign-offer-number">
            {offerLength}&nbsp;
          </CampaignHeadlineOfferNumber>
          <CampaignHeadline id="campaign-headline">
            {`${headline}`}
          </CampaignHeadline>
        </CampaignHeadlineContainer>
      )}
      {subheadline && (
        <>
          <CampaignSubHeadline id="campaing-subhealine">
            {subheadline}
          </CampaignSubHeadline>
          <Spacing20 />
        </>
      )}
      {termsAndConditions && (
        <>
          <TermsAndConditions id="campaing-terms" html={termsAndConditions} />
        </>
      )}
    </ContainerTemplate>
  )
}

CampaignOfferHeader.propTypes = {
  introText: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({
      html: PropTypes.string,
    }),
  }),
  offerLength: PropTypes.number,
  headline: PropTypes.string,
  subheadline: PropTypes.string,
  termsAndConditions: PropTypes.string,
}

CampaignOfferHeader.defaultProps = {
  introText: { childMarkdownRemark: { html: '' } },
  offerLength: 0,
  headline: '',
  subheadline: '',
  termsAndConditions: '',
}

export default CampaignOfferHeader
