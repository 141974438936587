import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import useScrollListener from 'hooks/useScrollListener'
import PreviewTab from './PreviewTab'
import { GridPreview } from './GridPreview.style'

const structureSize = isMobile ? [4, 8, 16] : [8, 16, 24]

const getPreviewStructure = (items) => {
  if (items.length <= (isMobile ? 8 : 16))
    return [
      items.slice(0, structureSize[0]),
      items.slice(structureSize[0], structureSize[1]),
    ]
  return [
    items.slice(0, structureSize[0]),
    items.slice(structureSize[0], structureSize[1]),
    items.slice(structureSize[1], structureSize[2]),
  ]
}

const GridPreviewC = ({ items, isActive, onDismiss, onOfferClick }) => {
  const [activeTab, switchActiveTab] = React.useState(0)
  const previewData = getPreviewStructure(items)
  const previousTab = React.useRef(null)
  const columnSwitchRef = React.useRef(null)

  const { isScrolling } = useScrollListener({
    delayAmount: { scroll: 125, iddle: 500 },
    onScroll: (event) => {
      if (window && event.target) {
        const scrollPosition = window.scrollY + window.innerHeight
        const pagePosition =
          event.target.documentElement.scrollHeight - (isMobile ? 1150 : 800)
        if (scrollPosition > pagePosition) onDismiss()
      }
    },
    onIddle: () => {
      // eslint-disable-next-line no-use-before-define
      setTimeout(onSwitchColumns, 1000)
    },
  })

  const onSwitchColumns = () => {
    if (!isScrolling) {
      switchActiveTab((currentState) => {
        const nextTab = currentState + 1
        previousTab.current = currentState
        return nextTab >= previewData.length || nextTab > 4 ? 0 : nextTab
      })
    }
  }

  React.useEffect(() => {
    columnSwitchRef.current = setTimeout(onSwitchColumns, 4000)

    return () => clearTimeout(columnSwitchRef.current)
  }, [previewData, onSwitchColumns])

  return (
    <GridPreview hide={!isActive}>
      {previewData.map((panelItems, index) => (
        <PreviewTab
          isActive={index === activeTab}
          wasActive={index === previousTab.current}
          items={panelItems}
          onOfferclick={onOfferClick}
          tabIndex={index}
        />
      ))}
    </GridPreview>
  )
}

GridPreviewC.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array]),
  isActive: PropTypes.bool,
  onDismiss: PropTypes.func,
  onOfferClick: PropTypes.func,
}

GridPreviewC.defaultProps = {
  items: [],
  isActive: false,
  onDismiss: () => null,
  onOfferClick: () => null,
}

export default GridPreviewC
