import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import GalleryViewIcon from 'components/Icons/GalleryViewIcon'
import { Button, Icon, Slider } from './Toggle.style'

const Toggle = ({ on, onPress }) => (
  <Slider on={on}>
    <Button on={on} onClick={onPress}>
      <Icon>
        <GalleryViewIcon color={theme.colors.teal} />
      </Icon>
    </Button>
  </Slider>
)

Toggle.propTypes = {
  on: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
}

export default Toggle
