import React from 'react'
import PropTypes from 'prop-types'
import {
  VisitCardSectionWrapper,
  VisitCardTextWrapper,
  VisitCardTitleWrapper,
  VisitCardWrapper,
  VisitPageWrapper,
} from './VisitCard.Style'

const VisitCard = ({
  text,
  title,
  colour,
  onClick,
  animate,
  delay,
  isCardSelected,
}) => {
  return (
    <VisitPageWrapper
      animate={animate}
      delay={delay}
      isCardSelected={isCardSelected}>
      <VisitCardWrapper colour={colour}>
        <VisitCardTextWrapper dangerouslySetInnerHTML={{ __html: text }} />
        <VisitCardSectionWrapper />
        <VisitCardTitleWrapper onClick={onClick}>{title}</VisitCardTitleWrapper>
      </VisitCardWrapper>
    </VisitPageWrapper>
  )
}

VisitCard.propTypes = {
  colour: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  animate: PropTypes.bool,
  delay: PropTypes.number,
  isCardSelected: PropTypes.bool,
}

VisitCard.defaultProps = {
  colour: '',
  text: '',
  title: '',
  onClick: () => {},
  animate: false,
  delay: 0,
  isCardSelected: false,
}

export default VisitCard
