import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { OffersContextDefinition } from 'contexts/offers'
import NewOfferCard from 'components/NewOfferCard/NewOfferCard'
import PreviewColumn from './PreviewColumn'
import { PreviewTab } from './GridPreview.style'

const tabSize = isMobile ? 2 : 4

const PreviewTabC = ({
  items,
  tabIndex,
  isActive,
  wasActive,
  onOfferclick,
}) => {
  const { village } = React.useContext(OffersContextDefinition)
  const mappedColumns = React.useMemo(() => {
    const columns = []

    for (let i = 0; i < tabSize; i += 1) {
      const firstElement = items[i]
      const secondElement = items[i + tabSize]
      const defaultDelay = i * 0.5
      const animationDelay = isActive ? defaultDelay + 0.4 : defaultDelay

      if (firstElement) {
        columns.push(
          <PreviewColumn
            anmDelay={animationDelay}
            isActive={isActive}
            wasActive={wasActive}>
            <NewOfferCard
              setIndexSelected={(offerIndex) =>
                onOfferclick(offerIndex + tabIndex * 8)
              }
              village={village}
              index={i}
              {...firstElement}
            />
            {secondElement && (
              <NewOfferCard
                village={village}
                setIndexSelected={(offerIndex) =>
                  onOfferclick(offerIndex + tabIndex * 8)
                }
                index={i + tabSize}
                {...secondElement}
              />
            )}
          </PreviewColumn>
        )
      }
    }
    return columns
  }, [items])

  return <PreviewTab>{mappedColumns}</PreviewTab>
}

PreviewTabC.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array]),
  tabIndex: PropTypes.number,
  isActive: PropTypes.bool,
  wasActive: PropTypes.bool,
  onOfferclick: PropTypes.func,
}

PreviewTabC.defaultProps = {
  items: [],
  tabIndex: 0,
  isActive: false,
  wasActive: false,
  onOfferclick: () => null,
}

export default PreviewTabC
