import styled, { css } from 'styled-components'

export const ParallaxBox = styled.div`
  perspective: 150px;
  transform-style: preserve-3d;
  position: relative;
  will-change: scroll-position;

  ${(props) =>
    props?.extraStyle
      ? css`
          ${props.extraStyle}
        `
      : ''}
`

export default {
  ParallaxBox,
}
