import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { OffersContextDefinition } from 'contexts/offers'
import useScrollListener, { SCROLL_DIRECTIONS } from 'hooks/useScrollListener'
import { CloseIcon } from 'components/Icons'
import {
  MobileButtonCloseWrapper,
  MobileButtonWrapper,
  MobileCenterWrapper,
  MobileCloseWrapper,
  MobileTextWrapper,
} from './MobileButton.style'

const delayAmount = { scroll: 500, iddle: 500 }

const MobileButton = ({ onClick, closeButton, targetElement }) => {
  const {
    floatingIslandEnabled,
    displayList,
    entitySettings: { floatIslandOnListViewBG, offerCardPrimaryBG },
  } = useContext(OffersContextDefinition)

  const { scrollDirection } = useScrollListener({
    targetElement,
    delayAmount,
  })

  const shouldHideButton =
    !floatingIslandEnabled || scrollDirection === SCROLL_DIRECTIONS.down

  return (
    <>
      {!closeButton && (
        <MobileButtonWrapper
          onClick={onClick}
          bg={displayList && `#${floatIslandOnListViewBG}`}
          hide={shouldHideButton}>
          <MobileCenterWrapper>
            <MobileTextWrapper>A-Z</MobileTextWrapper>
          </MobileCenterWrapper>
        </MobileButtonWrapper>
      )}
      {closeButton && (
        <MobileButtonCloseWrapper bg={displayList && `#${offerCardPrimaryBG}`}>
          <MobileCloseWrapper onClick={onClick}>
            <CloseIcon color="#fff" />
          </MobileCloseWrapper>
        </MobileButtonCloseWrapper>
      )}
    </>
  )
}

MobileButton.propTypes = {
  onClick: PropTypes.func,
  closeButton: PropTypes.bool,
  targetElement: PropTypes.oneOfType([PropTypes.object]),
}

MobileButton.defaultProps = {
  onClick: () => {},
  closeButton: false,
  targetElement: null,
}

export default MobileButton
