import React, { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { OffersContextDefinition } from 'contexts/offers'
import MobileButton from 'components/MobileButton/MobileButton'
import MobileFrame from 'components/MobileFrame/MobileFrame'
import NewOfferGrid from 'components/NewOfferGrid/NewOfferGrid'
import OfferList from 'components/OffersList/OffersList'
import MobileOfferList from 'components/OffersList/MobileOfferList'
import OffersFloatingIsland from './OffersFloatingIsland'
import OffersCategoriesFilter from './OffersCategoriesFilter'
import { Section } from './OffersSection.style'

const OffersSection = ({ gridProps }) => {
  const {
    displayCategories,
    displayList,
    floatingIslandEnabled,
    mobileFrame,
    isMobile,
    toggleMobileFrame,
    entitySettings: {
      floatIslandBG,
      floatIslandOnListViewBG,
      offerCardPrimaryBG,
    },
  } = useContext(OffersContextDefinition)

  const sectionRef = useRef()

  useEffect(() => {
    if (!displayList) {
      const pageElement = document.querySelector('body div')
      if (pageElement) pageElement.removeAttribute('style')
    }
  }, [displayList])

  return (
    <Section ref={sectionRef}>
      {isMobile ? (
        <>
          <OffersFloatingIsland />
          {displayList && (
            <MobileOfferList>
              <OfferList detailsExtraProps={gridProps} />
              {displayCategories && <OffersCategoriesFilter stickToBottom />}
              {mobileFrame && (
                <MobileFrame
                  isVisible={mobileFrame}
                  bg={
                    displayList ? `#${floatIslandOnListViewBG}` : floatIslandBG
                  }
                  buttonBg={displayList && `#${offerCardPrimaryBG}`}
                />
              )}
            </MobileOfferList>
          )}
          <NewOfferGrid {...gridProps} />
          {displayCategories && <OffersCategoriesFilter stickToBottom />}
          {floatingIslandEnabled && (
            <MobileButton
              onClick={toggleMobileFrame}
              closeButton={mobileFrame}
            />
          )}
          {mobileFrame && (
            <MobileFrame
              isVisible={mobileFrame}
              bg={displayList ? `#${floatIslandOnListViewBG}` : floatIslandBG}
              buttonBg={displayList && `#${offerCardPrimaryBG}`}
            />
          )}
        </>
      ) : (
        <>
          <OffersFloatingIsland />
          {displayList ? (
            <OfferList detailsExtraProps={gridProps} />
          ) : (
            <NewOfferGrid {...gridProps} />
          )}
        </>
      )}
    </Section>
  )
}

OffersSection.propTypes = {
  showFloatingIsland: PropTypes.bool,
  islandMethods: PropTypes.shape({
    hideFloatingIsland: PropTypes.func,
    openFloatingIsland: PropTypes.func,
  }),
  offers: PropTypes.arrayOf(PropTypes.shape({})),
  gridProps: PropTypes.shape({
    heroCTA1: PropTypes.string,
    heroCTA1Link: PropTypes.string,
    visitCardProps: PropTypes.shape({
      text: PropTypes.string,
      title: PropTypes.string,
      colour: PropTypes.string,
    }),
    hideTopOffers: PropTypes.bool,
    village: PropTypes.shape({}),
    settings: PropTypes.shape({}),
    nodeLocale: PropTypes.string,
    newOfferProps: PropTypes.shape({}),
  }),
}

OffersSection.defaultProps = {
  showFloatingIsland: false,
  islandMethods: {
    hideFloatingIsland: () => {},
    openFloatingIsland: () => {},
  },
  offers: [],
  gridProps: {
    heroCTA1: '',
    heroCTA1Link: '#',
    visitCardProps: {
      text: '',
      title: '',
      colour: '',
    },
    village: {},
    nodeLocale: '',
    settings: {},
    hideTopOffers: false,
    newOfferProps: {
      showArrow: true,
      isTransitioning: false,
      showFullGrid: false,
      openAllOffersClick: () => {},
    },
  },
}

export default OffersSection
