import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const GalleryViewIcon = ({ color, width, display }) => (
  <IconWrapper width={width} display={display}>
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="ri:gallery-view-2">
        <path
          fill={color}
          id="Vector"
          d="M3.08024 2.55566C2.86489 2.55566 2.65837 2.64121 2.5061 2.79348C2.35383 2.94575 2.26828 3.15228 2.26828 3.36762V8.23939C2.26828 8.45473 2.35383 8.66126 2.5061 8.81353C2.65837 8.9658 2.86489 9.05135 3.08024 9.05135H8.76396C8.97931 9.05135 9.18583 8.9658 9.3381 8.81353C9.49038 8.66126 9.57592 8.45473 9.57592 8.23939V3.36762C9.57592 3.15228 9.49038 2.94575 9.3381 2.79348C9.18583 2.64121 8.97931 2.55566 8.76396 2.55566H3.08024ZM3.8922 7.42742V4.17958H7.952V7.42742H3.8922ZM3.08024 10.6753C2.86489 10.6753 2.65837 10.7608 2.5061 10.9131C2.35383 11.0654 2.26828 11.2719 2.26828 11.4872V16.359C2.26828 16.5743 2.35383 16.7809 2.5061 16.9331C2.65837 17.0854 2.86489 17.1709 3.08024 17.1709H8.76396C8.97931 17.1709 9.18583 17.0854 9.3381 16.9331C9.49038 16.7809 9.57592 16.5743 9.57592 16.359V11.4872C9.57592 11.2719 9.49038 11.0654 9.3381 10.9131C9.18583 10.7608 8.97931 10.6753 8.76396 10.6753H3.08024ZM3.8922 15.547V12.2992H7.952V15.547H3.8922ZM11.1998 3.36762C11.1998 3.15228 11.2854 2.94575 11.4377 2.79348C11.5899 2.64121 11.7965 2.55566 12.0118 2.55566H17.6955C17.9109 2.55566 18.1174 2.64121 18.2697 2.79348C18.4219 2.94575 18.5075 3.15228 18.5075 3.36762V8.23939C18.5075 8.45473 18.4219 8.66126 18.2697 8.81353C18.1174 8.9658 17.9109 9.05135 17.6955 9.05135H12.0118C11.7965 9.05135 11.5899 8.9658 11.4377 8.81353C11.2854 8.66126 11.1998 8.45473 11.1998 8.23939V3.36762ZM12.8238 4.17958V7.42742H16.8836V4.17958H12.8238ZM12.0118 10.6753C11.7965 10.6753 11.5899 10.7608 11.4377 10.9131C11.2854 11.0654 11.1998 11.2719 11.1998 11.4872V16.359C11.1998 16.5743 11.2854 16.7809 11.4377 16.9331C11.5899 17.0854 11.7965 17.1709 12.0118 17.1709H17.6955C17.9109 17.1709 18.1174 17.0854 18.2697 16.9331C18.4219 16.7809 18.5075 16.5743 18.5075 16.359V11.4872C18.5075 11.2719 18.4219 11.0654 18.2697 10.9131C18.1174 10.7608 17.9109 10.6753 17.6955 10.6753H12.0118ZM12.8238 15.547V12.2992H16.8836V15.547H12.8238Z"
        />
      </g>
    </svg>
  </IconWrapper>
)

GalleryViewIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
}

GalleryViewIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
}

export default GalleryViewIcon
