import React from 'react'
import PropTypes from 'prop-types'
import { PreviewColumn } from './GridPreview.style'

const PreviewColumnC = ({ children, anmDelay, isActive, wasActive }) => {
  const columnRef = React.useRef(null)

  React.useEffect(() => {
    if (columnRef.current) {
      const timeout = setTimeout(() => {
        if (isActive) {
          columnRef.current.style.opacity = 1
          columnRef.current.style.zIndex = 2
        }
        if (wasActive) {
          columnRef.current.style.opacity = 0
          columnRef.current.style.zIndex = -1
        }
      }, anmDelay * 1500)

      return () => clearTimeout(timeout)
    }

    return () => null
  }, [isActive, wasActive])

  return (
    <PreviewColumn
      ref={columnRef}
      anmDelay={anmDelay}
      isActive={isActive}
      wasActive={wasActive}>
      {children}
    </PreviewColumn>
  )
}

PreviewColumnC.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.object,
  ]).isRequired,
  anmDelay: PropTypes.number,
  isActive: PropTypes.bool,
  wasActive: PropTypes.bool,
}

PreviewColumnC.defaultProps = {
  anmDelay: 0,
  isActive: false,
  wasActive: false,
}

export default PreviewColumnC
