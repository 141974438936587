import React from 'react'
import PropTypes from 'prop-types'
import theme from 'styles/theme'
import IconWrapper from './IconWrapper'

const ArrowSettingsIcon = ({ color, width, display, transform }) => (
  <IconWrapper
    width={width}
    height="100%"
    display={display}
    transform={transform}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="34"
      viewBox="0 0 34 34"
      fill="none">
      <path
        d="M17 7.375L17 26.625"
        stroke="white"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={color}
        transform={transform}
      />
      <path
        d="M26.625 17L17 26.625L7.375 17"
        stroke="white"
        strokeWidth="1.65"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform={transform}
      />
    </svg>
  </IconWrapper>
)

ArrowSettingsIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  display: PropTypes.string,
  transform: PropTypes.string,
}

ArrowSettingsIcon.defaultProps = {
  color: theme.colors.coaldark,
  width: [],
  display: 'inline-block',
  transform: 'rotate(0)',
}

export default ArrowSettingsIcon
