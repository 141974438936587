import React, { useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'
import { Section } from './FullscreenModal.style'

function ModalComponent({ children, background }, ref) {
  useLayoutEffect(() => {
    const pageElement = document.querySelector('body div')
    if (pageElement) pageElement.setAttribute('style', 'overflow: hidden;')
  }, [])
  return createPortal(
    <Section ref={ref} background={background}>
      {children}
    </Section>,
    document.body
  )
}

ModalComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

ModalComponent.defaultProps = {
  background: undefined,
}

const FullscreenModal = React.forwardRef(ModalComponent)

export default FullscreenModal
