import { useCallback, useEffect, useRef } from 'react'
import useScrollListener from './useScrollListener'

const SPEED = 80
const SMOOTH = 20

const normalizeWheelDelta = (e) => {
  if (e.detail) {
    if (e.wheelDelta) {
      return (e.wheelDelta / e.detail / 40) * (e.detail > 0 ? 1 : -1) // Opera
    }
    return -e.detail / 3 // Firefox
  }
  return e.wheelDelta / 120 // IE,Safari,Chrome
}

const requestFrameFunc = () =>
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.oRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  ((callback) => window.setTimeout(callback, 1000 / 50))

/**
 *
 * @param {any=} targetElement If passed, it controls the scroll behavior of this specifc element, defaul to
 * @param {number=} speed Sets the speed or the proportional scrolled amount when using the mouse wheel, default to 50
 * @param {number=} smooth Defines the butter effect, the smootheness amount when scrolling, default to 75
 */
export default (targetElement, speed = SPEED, smooth = SMOOTH) => {
  const target = useRef(null)
  const isMoving = useRef(false)
  const pos = useRef(0)

  const update = useCallback(() => {
    isMoving.current = true
    const delta = (pos.current - target.current.scrollTop) / smooth
    target.current.scrollTop += delta

    if (Math.abs(delta) > 0.95) {
      requestFrameFunc()(update)
    } else {
      isMoving.current = false
    }
  }, [])

  const outerScrollSync = useCallback(() => {
    pos.current = target.current.scrollTop
  }, [])

  const smoothScroll = useCallback((e) => {
    e.preventDefault() // disable default scrolling

    const frame =
      target.current === document.body && document.documentElement
        ? document.documentElement
        : target.current // safari is the new IE

    const delta = normalizeWheelDelta(e)

    pos.current += -delta * speed
    pos.current = Math.max(
      0,
      Math.min(pos.current, target.current.scrollHeight - frame.clientHeight)
    ) // limit the scrolling

    if (!isMoving.current) requestFrameFunc()(update)
  }, [])

  useScrollListener({
    delayAmount: { iddle: 250, scroll: 1000 },
    onIddle: outerScrollSync,
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      target.current = targetElement || (!!window && window.document) || null

      if (target.current === document) {
        target.current =
          document.scrollingElement ||
          document.documentElement ||
          document.body.parentNode ||
          document.body // cross browser support for document scrolling
      }

      pos.current = target.current.scrollTop

      if (target.current) {
        target.current.addEventListener('wheel', smoothScroll, {
          passive: false,
        })

        return () => {
          target.current.removeEventListener('wheel', smoothScroll)
        }
      }
      return () => null
    }
    return () => null
  }, [])
}
