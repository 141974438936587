import styled, { keyframes, css } from 'styled-components'
import theme from 'styles/theme'

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`

export const ArrowPageWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  animation: ${(props) =>
    props.animation
      ? css`
          ${bounce} 1s infinite
        `
      : 'none'};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '-30px')};
  position: absolute;
  width: 100vw; /* Full viewport width */
  z-index: 7; /* Ensure it's above other components */

  ${theme.mediaQueries.smallOnly} {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '-6%')};
  }

  ${theme.mediaQueries.ipad} {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '-30px')};
  }
`

export const ArrowWrapper = styled.div`
  display: flex;
  width: 60px;
  height: 60px;
  transform: rotate(90deg);
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 50px;
  background: var(--Primary-Teal, ${(props) => props.colour});
  z-index: 8; /* Ensure it's above other components */
`
export const ArrowIconWrapper = styled.div`
  width: 33px;
  height: 33px;
  flex-shrink: 0;
`

export default {
  ArrowWrapper,
  ArrowIconWrapper,
  ArrowPageWrapper,
}
